<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">当地岗位服务费月度挂账</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active">
          当地岗位服务费月度挂账</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">缩写名称:</div>
          <el-input
            v-model="abbreviationName"
            placeholder="缩写名称"
            clearable
          ></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">项目名称:</div>
          <el-select
            v-model="projectID"
            placeholder="项目名称"
            filterable
            clearable
            @change="changeProjectID"
            class="card-select"
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">分项目名称:</div>
          <el-select
            v-model="subProjectID"
            placeholder="分项目名称"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in subProjectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">单位名称:</div>
          <el-select
            v-model="companyID"
            placeholder="岗位名称"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">部门名称:</div>
          <el-select
            v-model="departmentID"
            placeholder="岗位名称"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.ID"
              :label="item.DepartmentCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">甲方:</div>
          <el-select
            v-model="firstParty"
            placeholder="甲方"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in GovernmentList"
              :key="item.ID"
              :label="item.NameinChinese"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">是否加入审批:</div>
          <el-select
            v-model="applicationCreated"
            placeholder="是否加入审批"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">挂账日期:</div>
          <el-date-picker
            v-model="daterange1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button
          class="btn-pre"
          @click="getManpowerSupplierMonthlyCostApplicationDetails(1)"
        >
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add btn-pre" v-if="powers.add" @click="newBuilt">
          <i class="fa ion-plus-round"></i>新建
        </button>
        <button
          :class="['btn-add btn-pre', { 'btn-dis': applicationCreated }]"
          :disabled="applicationCreated"
          v-if="powers.add"
          @click="addApproval"
        >
          <i class="fa ion-plus-round"></i>添加审批表
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="AppplicationNumber"
            label="付款通知单编号"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.Main">
                {{ scope.row.Main.ApplicationNumber }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="MonthlyCost" label="挂账月份" width="80">
            <template slot-scope="scope">
              {{ scope.row.MonthlyCost | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column prop="FirstPartyName" label="甲方" width="150" />
          <el-table-column
            prop="ProjectName"
            label="项目名称"
            width="100"
          /><el-table-column
            prop="SubProjectName"
            label="分项目名称"
            width="100"
          />
          <el-table-column prop="WorkingUnit" label="用工单位" width="100" />

          <el-table-column
            prop="AbbreviationName"
            label="岗位外包单位名称缩写"
            width="150"
          />
          <el-table-column prop="PaymentPurpose" label="付款事项" width="180" />
          <el-table-column
            prop="AmountWithoutVAT"
            label="发票不含VAT额"
            width="110"
          >
          </el-table-column>
          <el-table-column prop="VATAmount" label="增值税额" width="80" />
          <el-table-column
            prop="AmountWithVAT"
            label="发票含VAT额"
            width="100"
          />
          <el-table-column prop="AmountinWord" label="大写" width="100" />

          <el-table-column prop="CostCenterName" label="成本中心" width="100" />
          <el-table-column
            prop="CostCenterCode"
            label="成本中心编号"
            width="120"
          />
          <el-table-column prop="MDMSystemSN" label="MDM系统编号" width="110" />
          <el-table-column
            prop="ServiceAgreementSN"
            label="合同编号"
            width="110"
          />
          <el-table-column
            prop="CompanyNameofBeneficiary"
            label="收款单位名称"
            width="110"
          />
          <el-table-column prop="BankCode" label="收款银行代码" width="110" />
          <el-table-column
            prop="BankNameofBeneficiary"
            label="收款银行名称"
            width="110"
          />
          <el-table-column
            prop="POBoxAndOfficeAddress"
            label="收款人地址"
            width="110"
          />
          <el-table-column
            prop="ContactNumber"
            label="联络人联系电话"
            width="110"
          />

          <el-table-column prop="Remark" label="备注" width="120" />
          <el-table-column prop="AuditStatus" label="审批状态" width="130">
            <template slot-scope="scope">
              <div v-if="scope.row.Main">
                <div
                  v-if="scope.row.Main.AuditStatus === null"
                  class="label-primary approval"
                >
                  待发布
                </div>
                <div
                  v-if="scope.row.Main.AuditStatus === 0"
                  class="label-primary approval"
                >
                  待审核
                </div>
                <div
                  v-if="scope.row.Main.AuditStatus === 1"
                  class="label-success approval"
                >
                  审核通过
                </div>
                <div
                  v-if="scope.row.Main.AuditStatus === 2"
                  class="label-info approval"
                >
                  审核中
                </div>
                <div
                  v-if="scope.row.Main.AuditStatus === 3"
                  class="label-warning approval"
                >
                  审核未通过
                </div>
                <div
                  v-if="scope.row.Main.AuditStatus === 4"
                  class="label-danger approval"
                >
                  驳回
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <i class="fa fa-file-text" @click="goDetails(scope.row)"></i>
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                @click="
                  deleteManpowerSupplierMonthlyCostApplicationDetail(scope.row)
                "
                v-if="powers.delete"
              ></i>
              <i
                class="fa fa-print"
                v-if="(powers.export || powers.print) && scope.row.MainKeyID"
                @click="goUrl(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getManpowerSupplierMonthlyCostApplicationDetails(1)"
          @current-change="getManpowerSupplierMonthlyCostApplicationDetails"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="当地岗位服务外包费明细"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">挂账月份:</div>
            <el-date-picker
              v-model="parameter.MonthlyCost"
              value-format="yyyy-MM-dd"
              format="yyyy-MM"
              type="month"
              placeholder="选择月份"
            >
            </el-date-picker>
          </div>

          <div class="from-box">
            <div class="name">甲方:</div>
            <el-select
              v-model="parameter.FirstParty"
              placeholder="甲方"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in GovernmentList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">项目名称:</div>
            <el-select
              v-model="parameter.ProjectID"
              placeholder="项目名称"
              filterable
              clearable
              @change="changeParameter"
              class="card-select"
            >
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </div>

          <div class="from-box">
            <div class="name">分项目名:</div>
            <el-select
              v-model="parameter.SubProjectID"
              placeholder="分项目名"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in subProjectList2"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">用工单位:</div>
            <el-select
              v-model="parameter.CompanyID"
              placeholder="用工单位"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>

          <div class="from-box">
            <div class="name">岗位外包单位:</div>
            <el-select
              v-model="parameter.SupplierKeyID"
              class="card-select"
              placeholder="岗位外包单位"
              filterable
              clearable
            >
              <el-option
                v-for="item in purchaseOrderApplicationList"
                :key="item.KeyID"
                :label="`${item.SupplierName}(${item.AbbreviationName})`"
                :value="item.KeyID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box2">
          <div class="text-title">模板文件</div>
          <div class="text-name" @click="getDownloadUrl(downloadUrl)">
            当地岗位服务外包费明细导入模板
          </div>
        </div>
        <div class="line-box2">
          <div class="text-title">上传附件</div>
          <div class="upload-box2">
            <div class="left">
              <div class="name" v-if="name">
                <div class="name-title" @click="getDownloadUrl(downloadUrl2)">
                  {{ name }}
                </div>
                <span v-if="name"
                  ><i
                    class="el-icon-delete"
                    @click="
                      name = null;
                      downloadUrl2 = null;
                      parameter.Path = null;
                    "
                  ></i
                ></span>
              </div>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment"
              ref="uploadRef"
            >
              <button class="btn-up">
                <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
              </button>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="handleClose" class="pop-close">关闭</el-button>
        <el-button @click="save" class="pop-save pop-right">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改付款方式"
      :visible.sync="dialogVisible2"
      width="800px"
      :before-close="handleClose2"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box2">
            <div class="name">付款方式:</div>
            <el-select
              v-model="parameter2.MethodOfPayment"
              placeholder="付款方式"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="handleClose2" class="pop-close">关闭</el-button>
        <el-button @click="save2" class="pop-save pop-right">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getManpowerSupplierMonthlyCostApplicationDetails,
  getGovernmentSystemDicAll,
  getPurchaseOrderApplicationSuppliers,
  importManpowerSupplierMonthlyCostDetailTemplate,
  addManpowerSupplierMonthlyCostApplicationDetail,
  deleteManpowerSupplierMonthlyCostApplicationDetail,
  addManpowerSupplierMonthlyCostApplication,
  putManpowerSupplierMonthlyCostApplicationDetail,
} from "@/api/table";
import {
  getProjectAll,
  getCompanyAll,
  getProjectsChildren,
  getDepartmentAll,
  uploadAttachment,
} from "@/api/user";
import Government from "@/components/tables/government.vue";
export default {
  filters: {
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 7);
      }
      return data;
    },
  },
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 10,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      options: [
        {
          value: 1,
          label: "公司",
        },
        {
          value: 2,
          label: "政府系统",
        },
      ],
      options2: [
        {
          value: false,
          label: "否",
        },
        {
          value: true,
          label: "是",
        },
      ],
      options3: [
        {
          value: 1,
          label: "Cash 现金",
        },
        {
          value: 2,
          label: "Check 支票",
        },
        {
          value: 3,
          label: "TT 电汇",
        },
        {
          value: 4,
          label: "Draft 汇票",
        },
      ],
      dialogVisible: false,
      dialogVisible2: false,
      parameter: {
        ID: null,
        KeyID: null,
        MonthlyCost: null,
        FirstParty: null,
        ProjectID: null,
        SubProjectID: null,
        CompanyID: null,
        DepartmentID: null,
        SupplierKeyID: null,
        Path: null,
      },
      parameter2: { ID: null, KeyID: null, MethodOfPayment: null },
      loading: true,
      projectList: [],
      GovernmentList: [],
      positionCategoryList: [],
      subProjectList: [],
      subProjectList2: [],
      companyList: [],
      departmentList: [],
      purchaseOrderApplicationList: [],
      abbreviationName: null,
      projectID: null,
      subProjectID: null,
      firstParty: null,
      companyID: null,
      departmentID: null,
      daterange1: null,
      applicationCreated: false,
      downloadUrl: null,
      downloadUrl2: null,
      name: null,
      selection: [],
    };
  },
  components: { Government },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.parameter = {
        ID: null,
        KeyID: null,
        MonthlyCost: null,
        FirstParty: null,
        ProjectID: null,
        SubProjectID: null,
        CompanyID: null,
        DepartmentID: null,
        SupplierKeyID: null,
        Path: null,
      };
      this.name = null;
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.parameter2 = {
        ID: null,
        KeyID: null,
        MethodOfPayment: null,
      };
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取表格
    getManpowerSupplierMonthlyCostApplicationDetails(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        abbreviationName: this.abbreviationName,
        projectID: this.projectID,
        subProjectID: this.subProjectID,
        firstParty: this.firstParty,
        companyID: this.companyID,
        departmentID: this.departmentID,
        applicationCreated: this.applicationCreated,
        startDate: this.daterange1 ? this.daterange1[0] : "",
        endDate: this.daterange1 ? this.daterange1[1] : "",
      };
      getManpowerSupplierMonthlyCostApplicationDetails(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //下载附件
    getDownloadUrl(url) {
      location.href = url;
    },

    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name = data.name;
          this.parameter.Path = res.response;
          this.downloadUrl2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
          console.log("this.name2", this.name2);
        }
      });
    },

    changeProjectID() {
      this.subProjectID = null;
      this.subProjectList = [];
      this.getProjectsChildren();
    },
    getProjectsChildren() {
      if (!this.projectID) {
        return;
      }
      getProjectsChildren({ id: this.projectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    changeParameter() {
      this.parameter.SubProjectID = null;
      this.subProjectList2 = [];
      this.getProjectsChildren2();
    },
    getProjectsChildren2() {
      if (!this.parameter.ProjectID) {
        return;
      }
      getProjectsChildren({ id: this.parameter.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList2 = res.response;
        }
      });
    },

    goDetails(data) {
      this.$router.push(
        `/module/monthlyCreditDetails?key=${data.KeyID}&state=${this.applicationCreated}`
      );
    },
    //新建
    newBuilt() {
      this.dialogVisible = true;
    },
    //下载附件
    getDownloadUrl(url) {
      location.href = url;
    },

    //修改付款方式
    edit(data) {
      console.log("data", data);
      this.dialogVisible2 = true;
      this.parameter2.ID = data.ID;
      this.parameter2.KeyID = data.KeyID;
      this.parameter2.MethodOfPayment = data.MethodOfPayment;
    },
    //保存
    save() {
      if (
        this.parameter.MonthlyCost &&
        this.parameter.MonthlyCost.length < 10
      ) {
        this.parameter.MonthlyCost = this.parameter.MonthlyCost + "-01";
      }
      console.log("this.parameter", this.parameter);

      addManpowerSupplierMonthlyCostApplicationDetail(this.parameter).then(
        (res) => {
          if (res.status === 200) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getManpowerSupplierMonthlyCostApplicationDetails();
          }
        }
      );
    },

    //保存
    save2() {
      putManpowerSupplierMonthlyCostApplicationDetail(this.parameter2).then(
        (res) => {
          if (res.status === 200) {
            this.$message.success("保存成功");
            this.handleClose2();
            this.getManpowerSupplierMonthlyCostApplicationDetails();
          }
        }
      );
    },

    //删除
    deleteManpowerSupplierMonthlyCostApplicationDetail(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteManpowerSupplierMonthlyCostApplicationDetail({
          key: data.KeyID,
        }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getManpowerSupplierMonthlyCostApplicationDetails();
          }
        });
      });
    },

    handleSelectionChange(e) {
      console.log("e", e);
      this.selection = e;
    },

    addApproval() {
      if (this.selection.length === 0) {
        this.$message.warning("请至少选中一行数据");
        return;
      }
      const data = {
        ID: null,
        KeyID: null,
        UploadingDocument1: null,
        UploadingDocument2: null,
        UploadingDocument3: null,
        UploadingDocument4: null,
        UploadingDocument5: null,
        EditState: 2,
        Details: [],
        Attachments: [],
      };
      this.selection.forEach((item) => {
        let detailsItem = {
          ID: item.ID,
          KeyID: item.KeyID,
          MainKeyID: item.MainKeyID,
        };
        data.Details.push(detailsItem);
      });

      this.$confirm("确认提交当前审批单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        addManpowerSupplierMonthlyCostApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("提交成功");
            this.getManpowerSupplierMonthlyCostApplicationDetails();
          }
        });
      });
    },

    //打印
    goUrl(data) {
      this.$router.push(
        `/print?type=26&print=${this.powers.print}&export=${this.powers.export}&key=${data.KeyID}&name=${data.Main.ApplicationNumber}`
      );
    },
  },

  created() {
    if (this.$route.query.state) {
      if (this.$route.query.state == "true") {
        this.applicationCreated = true;
      } else {
        this.applicationCreated = false;
      }
    }
    this.getButtonPower();
    this.getManpowerSupplierMonthlyCostApplicationDetails();

    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });

    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        console.log("res", res);
        this.GovernmentList = res.response;
      }
    });
    //岗位类别

    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });

    //岗位
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    getPurchaseOrderApplicationSuppliers().then((res) => {
      if (res.status === 200) {
        this.purchaseOrderApplicationList = res.response;
      }
    });

    importManpowerSupplierMonthlyCostDetailTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl = "https://hrms.baishulai.com/" + res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 143px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }

  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box2 {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 100%;
        }
      }
      .from-box {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 90px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
    }
    .line-box2 {
      display: flex;
      font-size: 16px;
      margin-bottom: 24px;
      align-items: center;
      .text-title {
        width: 80px;
        font-size: 16px;
        flex-shrink: 0;
        margin-right: 5px;
      }
      .text-name {
        color: #0076ff;
        cursor: pointer;
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
.upload-box2 {
  display: flex;
  height: 34px;
  width: 100%;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
      box-sizing: border-box;
      .name-title {
        width: 250px;
        overflow: hidden; /* 确保超出容器的内容被裁剪 */
        white-space: nowrap; /* 确保文本在一行内显示 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        cursor: pointer;
        color: #315efb;
        margin-left: 10px;
      }
      span {
        color: red;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  // .el-table__body,
  // .el-table__footer,
  // .el-table__header {
  //   table-layout: auto;
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
  // .el-table__header {
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
.pop-right {
  margin-right: 12px !important;
}
</style>
